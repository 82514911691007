import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationModel } from 'src/app/core/interfaces/authentication.model';
import { UsersState } from 'src/app/features/users/state/users.state';

@Directive({
  selector: '[permissionsRequired]',
  standalone: true
})
export class PermissionDirective {
  @Input() permissionsRequired: string[];  
  @Select(state => state.users.auth) auth$: Observable<AuthenticationModel>;
  worked: boolean;
  authSub: Subscription;

  constructor(
    private store: Store,
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<any>,
  ) {}

  ngOnInit(): void {
    this.authSub = this.store.select(UsersState.auth).subscribe(
      (s) => {
        if (this.permissionsRequired?.length) {
          if (!this.worked && s?.claims) {
            let result = false;
            if (this.permissionsRequired.length) {
              this.permissionsRequired?.forEach(p => {
                if (s?.claims?.indexOf(p) != -1) {
                  result = true;
                }
              })
            } else {
              result = true;
            }
  
            if (result) {
              this.vcr.createEmbeddedView(this.tpl);
            }
  
            this.worked = true;
          }
        } else {
          this.vcr.createEmbeddedView(this.tpl);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
  }
}
